section.post-list {
  
  h2.post-list-year {
    color: $vermillion;
  }

  li {
    list-style: none;
    font-size: 14px;
    
    margin-bottom: $spacing-unit;

    a {
      color: $black;
      font-weight: normal;
    }
    span.post-meta {
      color: $grey;
      margin-right: $spacing-unit;
    }
    
    .excerpt {
      // border-bottom: 1px solid $grey;
      padding-bottom: $spacing-unit;
    }
  }
}