
@function vp($type, $variant, $end) {
  @return "/fonts/vollkorn-4-105/" + $type + "/Vollkorn-" + $variant + "." + $end;
}

@mixin vollkorn($weight, $style, $name, $sname) {
  @font-face {
    font-family: 'Vollkorn';
    src: url(vp('EOT', $name, 'eot'));
    src: url(vp('EOT', $name, 'eot')) format('embedded-opentype'),
         url(vp('PS-OTF', $name, 'otf')) format('opentype'),
         url(vp('TTF', $name, 'ttf')) format('truetupe'),
         url(vp('WOFF', $name, 'woff')) format('woff'),
         url(vp('WOFF2', $name, 'woff2')) format('woff2');
    font-weight: $weight;
    font-style: $style;
  }
}

@include vollkorn(400, "normal", "Regular", "regular");
// @include vollkorn(400, "italic", "Italic", "italic");
@include vollkorn(500, "normal", "Medium", "medium");
// @include vollkorn(500, "italic", "MediumItalic", "medium italic");
@include vollkorn(600, "normal", "SemiBold", "semi bold");
// @include vollkorn(600, "italic", "SemiBoldItalic", "semi bold italic");
@include vollkorn(700, "normal", "Bold", "bold");
// @include vollkorn(700, "italic", "BoldItalic", "bold italic");
@include vollkorn(800, "normal", "ExtraBold", "extra bold");
// @include vollkorn(700, "italic", "ExtraBoldItalic", "extra bold italic");
@include vollkorn(900, "normal", "Black", "black");
// @include vollkorn(700, "italic", "BlackItalic", "black italic");



@font-face {
  font-family: 'PT Mono';
  src: local('PT Mono'), local('PTMono-Regular'),
       url("/fonts/pt-mono/PTM55F.ttf") format('ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'PT Mono';
  src: local('PT Mono'), local('PTMono-Regular'),
       url("/fonts/pt-mono/PTM75F.ttf") format('ttf');
  font-weight: 700;
}
