$text-color: $black;
$link-color: $sky_blue;
$link-hover-color: $orange;

/* Default */
* {
  font-family: $base-font-family;
  font-kerning: normal;
}

body {
  color: $black;
  font-size: $base-font-size;

  @media (max-width: 600px) {
    font-size: 0.8 * $base-font-size;
  }

}

/* Headlines */
h1 {
  line-height: 2em;
  font-size: 2em;
  font-weight: lighter;
}

h2 {
  margin-top: 1em;
  line-height: 2em;
}

h3 {
  line-height: 2em;
}


/* Links */
a {
  color: $link-color;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    color: $link-hover-color;
    text-decoration: underline;
  }
}

/**
 * Lists
 */
ul, ol {
  margin-left: $spacing-unit;
  padding: 0;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

.orange { color: $orange; }
.blue { color: $blue; }
.yellow { color: $yellow; }
.red { color: $vermillion; }
.green { color: $bluish_green; }
.purple { color: $reddish_purple; }

pre, code {
  font-family: 'PT Mono', monospace;

  span {
    font-family: 'PT Mono', monospace;
  }
}

blockquote {
  margin-left: 0;
  padding-left: $spacing-unit;
  border-left: 0.5*$spacing-unit solid #EEEEEE;
}

table {
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid $grey;
}