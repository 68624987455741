figure {
  border: 1px solid #EEEEEE;
  padding: $spacing-unit;

  margin: $spacing-unit 0 $spacing-unit 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    // width: 100%;
    // height: 100%;
    object-fit: scale-down;
  }

  figcaption {
    span.meta {
      font-style: italic;
    }
    margin-top: $spacing-unit;
  }
}
