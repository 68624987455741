body {
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: $page_width_desktop) {
    max-width: $page_width_desktop;
    max-width: $page_width_desktop;
  }

  main {
    padding-left: $spacing-unit;
    padding-right: $spacing-unit;
  }

  footer {
    padding-left: $spacing-unit;
    padding-right: $spacing-unit;
  }
}
