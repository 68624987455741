.two-columns {
  display: flex;

  .first {
    width: $page_width_desktop/2 - $spacing-unit/2;
  }

  .second {
    margin-left: $spacing-unit;
    width: $page_width_desktop/2 - $spacing-unit/2;
  }

  .c10 { width: 1*$page_width_desktop/10 - $spacing-unit/2; }
  .c20 { width: 2*$page_width_desktop/10 - $spacing-unit/2; }
  .c30 { width: 3*$page_width_desktop/10 - $spacing-unit/2; }
  .c40 { width: 4*$page_width_desktop/10 - $spacing-unit/2; }
  .c50 { width: 5*$page_width_desktop/10 - $spacing-unit/2; }
  .c60 { width: 6*$page_width_desktop/10 - $spacing-unit/2; }
  .c70 { width: 7*$page_width_desktop/10 - $spacing-unit/2; }
  .c80 { width: 8*$page_width_desktop/10 - $spacing-unit/2; }
  .c90 { width: 9*$page_width_desktop/10 - $spacing-unit/2; }
}

.spacer {
  margin-top: 2*$spacing-unit;
}