/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-block-start: $spacing-unit;
  margin-bottom: $spacing-unit / 2;
}

.divider {
  height: 2*$spacing-unit;
}
