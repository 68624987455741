
$page_width_mobile: 600px;
$page_width_desktop: 800px;
$spacing_unit: 16px;

$base-font-family: "Vollkorn", "Times New Roman", "Times", serif;
$base-font-size: 18px;


$white: #f8f6f6;
$grey: #989898;

// See https://www.nature.com/articles/nmeth.1618.pdf?origin=ppub
$black: rgb(0,0,0);
$orange: rgb(230,159,0);
$sky_blue: rgb(86,180,233);
$bluish_green: rgb(0,158,115);
$yellow: rgb(240,228,66); 
$blue: rgb(0,114,178);
$vermillion: rgb(213,94,0);
$reddish_purple: rgb(204,121,167);
