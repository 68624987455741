footer.site-footer {
  display: flex;

  margin-top: 2*$spacing-unit;
  padding-top: $spacing-unit;
  border-top: 1px solid $grey;

  color: $grey;

  p {
    flex: 1 1 0px;
  }

  p:not(:first-child) {
    padding-left: $spacing-unit
  }

  a {
    color: $bluish_green;
  }
}