// $article-header-height: 100px;
$article-header-text-size: 32px;

article {
  header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    margin-top: 2*$spacing-unit;
    margin-bottom: 2*$spacing-unit;
    
    h1 {
      // color: $article-header-headline-color;
      margin: 0;
      padding: 0;
      font-size: $article-header-text-size;
      line-height: $article-header-text-size;
    }

    .post-meta {
      // line-height: $article-header-text-size;
      margin: 0;
    }

    
  }

  .row {
    @extend %vertical-rhythm;
    display: flex;

    &.center {
      align-items: center;
    }
  }
  .col-2 { width: 16.7%; }
  .col-3 { width: 25%; }
  .col-4 { width: 33%; }
  .col-6 { width: 50%; }
  .col-9 { width: 75%; }


  div.tip-box {
    border: 1px solid green;
    @extend %vertical-rhythm;

    margin: $spacing-unit;
    padding: .5*$spacing-unit;
    text-align: center;

    font-size: 0.8em;

    &::before {
      content: "ProTipp™: "
    }
  }

  // for further styling see:
  // - base/typography

  footer.bibliography {
    border-top: 1px solid white;
  }
}
