/**
 * Syntax highlighting styles
 */
.highlight {
  background: #272822;
  color: $white;
  @extend %vertical-rhythm;

  pre {
    padding: $spacing-unit;
    overflow: auto;
  }


  // #e6db74 -> bluish_green
  // #ae81ff -> reddish_purple
  // #f92672 -> orange (maybe exchange with above?)
  // #66d9ef -> sky blue
  // #a6e22e -> blue
  // #75715e -> grey
  // #f8f8f2 -> white

  pre { background-color: #272822; }
  .hll { background-color: #272822; }
  .c { color: $grey } /* Comment */
  .err { color: $yellow; background-color: $vermillion } /* Error */
  .k { color: $sky_blue } /* Keyword */
  .l { color: $reddish_purple } /* Literal */
  .n { color: $white } /* Name */
  .o { color: $orange } /* Operator */
  .p { color: $white } /* Punctuation */
  .cm { color: $grey } /* Comment.Multiline */
  .cp { color: $grey } /* Comment.Preproc */
  .c1 { color: $grey } /* Comment.Single */
  .cs { color: $grey } /* Comment.Special */
  .ge { font-style: italic } /* Generic.Emph */
  .gs { font-weight: bold } /* Generic.Strong */
  .kc { color: $sky_blue } /* Keyword.Constant */
  .kd { color: $sky_blue } /* Keyword.Declaration */
  .kn { color: $orange } /* Keyword.Namespace */
  .kp { color: $sky_blue } /* Keyword.Pseudo */
  .kr { color: $sky_blue } /* Keyword.Reserved */
  .kt { color: $sky_blue } /* Keyword.Type */
  .ld { color: $bluish_green } /* Literal.Date */
  .m { color: $reddish_purple } /* Literal.Number */
  .s { color: $bluish_green } /* Literal.String */
  .na { color: $blue } /* Name.Attribute */
  .nb { color: $white } /* Name.Builtin */
  .nc { color: $blue } /* Name.Class */
  .no { color: $sky_blue } /* Name.Constant */
  .nd { color: $blue } /* Name.Decorator */
  .ni { color: $white } /* Name.Entity */
  .ne { color: $blue } /* Name.Exception */
  .nf { color: $blue } /* Name.Function */
  .nl { color: $white } /* Name.Label */
  .nn { color: $white } /* Name.Namespace */
  .nx { color: $blue } /* Name.Other */
  .py { color: $white } /* Name.Property */
  .nt { color: $orange } /* Name.Tag */
  .nv { color: $white } /* Name.Variable */
  .ow { color: $orange } /* Operator.Word */
  .w { color: $white } /* Text.Whitespace */
  .mf { color: $reddish_purple } /* Literal.Number.Float */
  .mh { color: $reddish_purple } /* Literal.Number.Hex */
  .mi { color: $reddish_purple } /* Literal.Number.Integer */
  .mo { color: $reddish_purple } /* Literal.Number.Oct */
  .sb { color: $bluish_green } /* Literal.String.Backtick */
  .sc { color: $bluish_green } /* Literal.String.Char */
  .sd { color: $bluish_green } /* Literal.String.Doc */
  .s2 { color: $bluish_green } /* Literal.String.Double */
  .se { color: $reddish_purple } /* Literal.String.Escape */
  .sh { color: $bluish_green } /* Literal.String.Heredoc */
  .si { color: $bluish_green } /* Literal.String.Interpol */
  .sx { color: $bluish_green } /* Literal.String.Other */
  .sr { color: $bluish_green } /* Literal.String.Regex */
  .s1 { color: $bluish_green } /* Literal.String.Single */
  .ss { color: $bluish_green } /* Literal.String.Symbol */
  .bp { color: $white } /* Name.Builtin.Pseudo */
  .vc { color: $white } /* Name.Variable.Class */
  .vg { color: $white } /* Name.Variable.Global */
  .vi { color: $white } /* Name.Variable.Instance */
  .il { color: $reddish_purple } /* Literal.Number.Integer.Long */
  .gh { } /* Generic Heading & Diff Header */
  .gu { color: $grey; } /* Generic.Subheading & Diff Unified/Comment? */
  .gd { color: $orange; } /* Generic.Deleted & Diff Deleted */
  .gi { color: $blue; } /* Generic.Inserted & Diff Inserted */
  .go { color: $white; } 
}
