header.site-header {
  height: 150px;
  background-color: $blue;
  color: $white;
  border-bottom: 3px solid $blue;

  h1 {
    margin: 0;
    width: fit-content;
    background-color: $blue;
  }

  a {
    margin-left: $spacing-unit;
    margin-right: $spacing-unit;
    color: $white;
  }

  @media (max-width: 600px) {
    height: 100px;
  }
}